import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProjectBtnComponent } from './projectBtnComponent';

interface Props {
    projectName: string,
    projectDesc: string,
    projectCreateWith: string,
    projectURL: string,
    projectRepoURL: string,
    thumbnailURL: string,
    btnClass1: string,
    btnClass2: string,
    btnDisable1: boolean,
    btnDisable2: boolean
}

function ProjectCardComponent({projectName, projectDesc, projectCreateWith, projectURL, projectRepoURL, thumbnailURL, btnClass1, btnClass2, btnDisable1, btnDisable2}: Props) {
    return (
        <Col xs={9} md={5} lg={3} className='pkmn-app-card'>
            <div className='thumbnail-image-div'>
                <img className='thumbnail-image' src={require(`../Assets/Images/ProjectThumbnails/${thumbnailURL}`)} />
            </div>
            <h5 className='project-card-title'>{projectName}</h5>
            <p>{projectDesc}</p>
            <p>{projectCreateWith}</p>
            <hr />
            <Link className='no-dec' target='_blank' rel='noopener noreferrer' to={projectURL}>
                <ProjectBtnComponent
                    btnTxt={'See This Project'}
                    btnClass={btnClass1}
                    btnDisable={btnDisable1}
                />
            </Link>
            <Link className='no-dec' target='_blank' rel='noopener noreferrer' to={projectRepoURL}>
                <ProjectBtnComponent
                    btnTxt={'See Github Repo'}
                    btnClass={btnClass2}
                    btnDisable={btnDisable2}
                />
            </Link>
        </Col>
    )
}

export { ProjectCardComponent }
import { Container, Row, Col } from "react-bootstrap"
import { ResumePage } from "./ResumePage"
import React from "react"

const newLocal = "resume-view-heading"
function ResumeView() {
  return (
    <Container fluid className="resume-page-body">
        <Row className="resume-page">
            <Col>
                <h1 className={newLocal}>My Resume</h1>
            </Col>
            <Col>
                <ResumePage />
            </Col>
            <br />
        </Row>
    </Container>
  )
}

export { ResumeView }
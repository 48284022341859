import React from 'react';

interface Props {
  btnTxt: string,
  btnClass: string,
  btnDisable: boolean
}

function ProjectBtnComponent({btnTxt, btnClass, btnDisable}: Props) {


  return (
    <div>
        <button className={btnClass} disabled={btnDisable}>
            {btnTxt}
        </button>
    </div>
  )
}

export { ProjectBtnComponent }
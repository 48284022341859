import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function ResumePage() {

    return (
        <Container className='resume-background'>
            <Row className="resume-style">
                <Col>
                    <h2 className='resume-headings'>Richard Johnson</h2>
                    <p>31rjohnsonjr@gmail.com</p>
                    <p>(209) 242-8966</p>
                    <Link target='_blank' rel='noopener noreferrer' to={'https://github.com/johnsonr31'}>My Github</Link>
                    <br />
                    <Link target='_blank' rel='noopener noreferrer' to={'https://www.linkedin.com/in/richard-johnson-9164a1158/'}>My LinkedIn</Link>

                </Col>
                <br />
                <Col>
                    <h3 className='resume-headings'>Summary</h3>
                    <p>A web developer with web design experience using ReactJS, as well as vanilla Javascript, HTML, and CSS. I have designed a few projects using my skills with these tools, such as an application that displays info about Pokemon, which pulls data from an API. I’ve made a second version of this project, which has several improvements.</p>
                </Col>
                <Col>
                    <h3 className='resume-headings'>Skills</h3>
                    <ul>
                        <li>Using HTML, CSS, and Javascript to build professional-looking frontend websites</li>
                        <li>Building web applications using the ReactJS framework.</li>
                    </ul>
                </Col>
                <Col>
                    <h3 className='resume-headings'>Experience</h3>
                    <ul>
                        <li>
                            <b>GameSwap</b>
                            <ul>
                                <li>
                                    This is a web application that was created in ReactJS and Bootstrap. It was a project for Code Stack academy, and I helped two teammates develop it. It is an application that allows people to find someone with a game they would like, which they can trade one of their games for. They can then message each other, and meet up to trade their games.
                                    <ul>
                                        <li>
                                            <Link target='_blank' rel='noopener noreferrer' to={'https://github.com/mannamar/gameswap.git'}>View The Github Repo</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Pokemon API</b>
                            <ul>
                                <li>This is a new and improved version of the Pokemon project above, created in ReactJS and Bootstrap. It fetches data from a Pokemon API so the user can get information about whatever Pokemon they search for.
                                    <ul>
                                        <li>
                                            <Link target='_blank' rel='noopener noreferrer' to={'https://github.com/johnsonr31/johnsonpokedex.git'}>View The Github Repo</Link>
                                        </li>
                                        <li>
                                            <Link target='_blank' rel='noopener noreferrer' to={'https://johnsonpokedex.vercel.app/'}>View This Project</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Col>
                <Col>
                    <h3 className='resume-headings'>Education</h3>
                    <ul>
                        <li>Be.Next</li>
                        <li>San Joaquin Delta College</li>
                        <li>Code Stack Academy</li>
                    </ul>
                </Col>
            </Row>
        </Container>

    )
}

export { ResumePage }
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProjectCardComponent } from './ProjectCardComponent';
import { SiJavascript, SiTypescript, SiHtml5, SiCss3, SiReact, SiBootstrap, SiVisualstudiocode, SiPostman, SiJirasoftware, SiCsharp } from "react-icons/si";

function HomeComponent() {
    return (
        <>
            <Container fluid className='home-header'>
                <Row className='header-row'>
                    <Col className='name-and-subtitle' xs={4}>
                        <Row className='hero-image-right-half'>
                            <Col>
                                <h1 className='name'>Richard Johnson</h1>
                                <p className='subtitle'>Frontend Developer</p>
                            </Col>
                            <br />
                            <Col>
                                <h2 className='skills-heading'>Skills</h2>
                            </Col>
                            <Col>
                                <Row className='skills-row'>
                                    <Col className='skill-icon'>
                                        <SiHtml5 className='icon-size' />
                                        <p>HTML5</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiCss3 className='icon-size' />
                                        <p>CSS3</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiJavascript className='icon-size' />
                                        <p>JavaScript</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiTypescript className='icon-size' />
                                        <p>TypeScript</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiCsharp className='icon-size' />
                                        <p>C#</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiVisualstudiocode className='icon-size' />
                                        <p>VS Code</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiPostman className='icon-size' />
                                        <p>Postman</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiJirasoftware className='icon-size' />
                                        <p>Jira</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiReact className='icon-size' />
                                        <p>ReactJS</p>
                                    </Col>
                                    <Col className='skill-icon'>
                                        <SiBootstrap className='icon-size' />
                                        <p>Bootstrap</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={8} lg={4}>
                        <img className='image-of-richard' title="It's me" alt='Richard Johnson, wearing a pair of glasses with black frames and a gray polo shirt.' src={require('../Assets/Images/richard-johnson-headshot.jpg')} />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='page-body'>
                <Container>
                    <br />
                    <Row>
                        <Col>
                            <h2 className='heading-text'>About Me</h2>
                            <p className='paragraph-text'>I am a developer with experience developing in the frontend. My main skills are in ReactJS and Bootstrap. I can also work with JavaScript, HTML and CSS. </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row className='projects-and-info-row'>
                        <Col>
                            <h2 className='heading-text'>Projects</h2>
                            <Row className='project-row'>
                                <ProjectCardComponent
                                    projectName={'Pokemon API Project'}
                                    projectDesc={'A project which pulls information from a Pokemon API for whichever Pokemon you want to search for.'}
                                    projectCreateWith={'Created using ReactJS, JavaScript, and Bootstrap.'}
                                    projectURL={'https://johnsonpokedex.vercel.app/'}
                                    projectRepoURL={'https://github.com/johnsonr31/johnsonpokedex.git'}
                                    thumbnailURL={'projectImg_pokemon2.png'}
                                    btnClass1={'pkmn-app-btn'}
                                    btnClass2={'pkmn-app-btn'}
                                    btnDisable1={false}
                                    btnDisable2={false}
                                />
                                <ProjectCardComponent
                                    projectName={'GameSwap'}
                                    projectDesc={'(NOTE: Only the Github Repo is currently available to view) An app where people can swap physical copies of video games with each other. I did some work on the frontend.'}
                                    projectCreateWith={'Created using ReactJS, TypeScript, and Bootstrap.'}
                                    projectURL={'https://github.com/mannamar/gameswap.git'}
                                    projectRepoURL={'https://github.com/mannamar/gameswap.git'}
                                    thumbnailURL={'projectImg_gameSwap.png'}
                                    btnClass1={'pkmn-app-btn not-working'}
                                    btnClass2={'pkmn-app-btn'}
                                    btnDisable1={true}
                                    btnDisable2={false}
                                />
                            </Row>
                        </Col>
                        <br />
                        <hr />
                        <Col>
                            <h2 className='heading-text'>Links</h2>
                            <Link className='no-dec' target='_blank' rel='noopener noreferrer' to='https://github.com/ricRobo4'>
                            <button className='pkmn-app-btn'>Github</button>
                            </Link>
                            <Link className='no-dec' target='_blank' rel='noopener noreferrer' to='https://www.linkedin.com/in/richard-johnson-9164a1158/'>
                            <button className='pkmn-app-btn'>LinkedIn</button>
                            </Link>
                        </Col>
                        <br />
                    </Row>
                    {/* <Row>
                        <ResumePage />
                    </Row> */}
                </Container>
                <br />
            </Container>
        </>
    )
}

export { HomeComponent }
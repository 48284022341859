import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HomeComponent } from './Components/HomeComponent';
import { ResumeView } from './Components/ResumeView';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavbarComponent from './Components/NavbarComponent';

function App() {
  return (
    <BrowserRouter>
      <>
        <NavbarComponent />
        {/* <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand>Richard Johnson</Navbar.Brand>
            <Nav className='me-auto'>
              <Nav.Link href='/'>Home</Nav.Link>
              <Nav.Link href='ResumeView'>Resume</Nav.Link>
            </Nav>
          </Container>
        </Navbar> */}
      </>
      <Routes>
        <Route path='/' element={<HomeComponent/>}/>
        <Route path='ResumeView' element={<ResumeView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

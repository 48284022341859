import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarComponent = () => {
  return (
      <div className='custom-nav'>
        <Container>
          <div>
            <p className='custom-nav-item site-title'>Richard Johnson</p>
          </div>
          <div>
            <Link to={'/'} className='custom-nav-item'>Home</Link>
          </div>  
          <div>
            <Link to={'ResumeView'} className='custom-nav-item'>Resume</Link>
          </div>
            
        </Container>
      </div>
  )
}

export default NavbarComponent;